<template>
<b-card class="w-100 mb-4" no-body>
    <div class="p-35">
        <div class="d-flex align-items-start">
            <div>
                <h4 class="card-title mb-1">Add Resource</h4>
                <b-card-text class="text-muted">
                </b-card-text>
            </div>
        </div>
        <b-alert
        variant="success"
        class="d-flex align-items-center bt-alert"
        :show="success"
        >
        {{ successMsg }}
        </b-alert>
        <b-alert
        variant="danger"
        class="d-flex align-items-center bt-alert"
        :show="error"
        >
        {{ errorMsg }}
        </b-alert>
    </div>
    <hr class="m-0" />
    <b-card-body>
        <h4 class="card-title">Product Info</h4>
        <b-row class="py-3 border-bottom align-items-center">
            <b-col sm="3" class="text-left text-md-right">
                <label class="fw-medium mb-0"> Category </label>
            </b-col>
            <b-col sm="9">
                <b-form-group><b-form-select :options="categories" v-model="$v.form.category_id.$model" :state="validateState('category_id')" ></b-form-select><b-form-invalid-feedback>This field is required</b-form-invalid-feedback></b-form-group>
            </b-col>
        </b-row>
        <b-row class="py-3 border-bottom align-items-center">
            <b-col sm="3" class="text-left text-md-right">
                <label class="fw-medium mb-0"> Sub-Category </label>
            </b-col>
            <b-col sm="9">
                <b-form-group><b-form-select :options="subcategories" v-model="$v.form.category_id.$model" :state="validateState('category_id')"></b-form-select><b-form-invalid-feedback>This field is required</b-form-invalid-feedback></b-form-group>
            </b-col>
        </b-row>
        <b-row class="border-bottom py-3 align-items-center">
            <b-col sm="3" class="text-left text-md-right">
                <label class="fw-medium mb-0">Registration</label>
            </b-col>
            <b-col sm="9">
               <b-form-group> <b-form-input id="registration" type="text" v-model="$v.form.registration.$model" :state="validateState('registration')" placeholder="Registration" required></b-form-input><b-form-invalid-feedback>This field is required</b-form-invalid-feedback> </b-form-group>               
            </b-col>
        </b-row>
        <b-row class="border-bottom py-3 align-items-center">
            <b-col sm="3" class="text-left text-md-right">
                <label class="fw-medium mb-0">Name</label>
            </b-col>
            <b-col sm="9">
               <b-form-group><b-form-input id="name" type="text" v-model="$v.form.name.$model" :state="validateState('name')" placeholder="Name" required></b-form-input><b-form-invalid-feedback>This field is required</b-form-invalid-feedback></b-form-group>
            </b-col>
        </b-row>
        <b-row class="border-bottom py-3 align-items-center">
            <b-col sm="3" class="text-left text-md-right">
                <label class="fw-medium mb-0">Year</label>
            </b-col>
            <b-col sm="9">
                <b-form-group><b-form-input id="year" type="text" v-model="form.year" placeholder="e.g. 1998" required></b-form-input><b-form-invalid-feedback>This field is required</b-form-invalid-feedback></b-form-group>
            </b-col>
        </b-row>
        <b-row class="border-bottom py-3 align-items-center">
            <b-col sm="3" class="text-left text-md-right">
                <label class="fw-medium mb-0">Model</label>
            </b-col>
            <b-col sm="9">
                <b-form-group><b-form-input id="model" type="text" v-model="form.model" placeholder="Model Number" required></b-form-input><b-form-invalid-feedback>This field is required</b-form-invalid-feedback></b-form-group>
            </b-col>
        </b-row>
        <b-row class="border-bottom py-3 align-items-center">
            <b-col sm="3" class="text-left text-md-right">
                <label class="fw-medium mb-0">Make</label>
            </b-col>
            <b-col sm="9">
                <b-form-group><b-form-input id="Make" type="text" v-model="form.make" placeholder="Make" required></b-form-input><b-form-invalid-feedback>This field is required</b-form-invalid-feedback></b-form-group>
            </b-col>
        </b-row>
        <b-row class="border-bottom py-3 align-items-center">
            <b-col sm="3" class="text-left text-md-right">
                <label class="fw-medium mb-0">Serial</label>
            </b-col>
            <b-col sm="9">
                <b-form-group><b-form-input id="Make" type="text" v-model="form.serial" placeholder="Serial" required></b-form-input><b-form-invalid-feedback>This field is required</b-form-invalid-feedback></b-form-group>
            </b-col>
        </b-row>
        <b-row class="border-bottom py-3 align-items-center">
            <b-col sm="3" class="text-left text-md-right">
                <label class="fw-medium mb-0">Engine</label>
            </b-col>
            <b-col sm="9">
                <b-form-group><b-form-input id="Make" type="text" v-model="form.engine" placeholder="Engine" required></b-form-input><b-form-invalid-feedback>This field is required</b-form-invalid-feedback></b-form-group>
            </b-col>
        </b-row>
        <b-row class="border-bottom py-3 align-items-center">
            <b-col sm="3" class="text-left text-md-right">
                <label class="fw-medium mb-0">Fuel</label>
            </b-col>
            <b-col sm="9">
                <b-form-group><b-form-input id="Make" type="text" v-model="form.fuel" placeholder="Gasoline" required></b-form-input><b-form-invalid-feedback>This field is required</b-form-invalid-feedback></b-form-group>
            </b-col>
        </b-row>
        <b-row class="border-bottom py-3 align-items-center">
            <b-col sm="3" class="text-left text-md-right">
                <label class="fw-medium mb-0">Transmission</label>
            </b-col>
            <b-col sm="9">
                <b-form-group><b-form-input id="Make" type="text" v-model="form.transmission" placeholder="Auto" required></b-form-input><b-form-invalid-feedback>This field is required</b-form-invalid-feedback></b-form-group>
            </b-col>
        </b-row>
        
        <b-row class="border-bottom py-3 align-items-center">
            <b-col sm="3" class="text-left text-md-right">
                <label class="fw-medium mb-0">Gross Weight</label>
            </b-col>
            <b-col sm="9">
                <b-form-group><b-form-input id="Make" type="text" v-model="form.gross_weight" placeholder="Kgs" required></b-form-input><b-form-invalid-feedback>This field is required</b-form-invalid-feedback></b-form-group>
            </b-col>
        </b-row>

        <b-row class="border-bottom py-3 align-items-center">
            <b-col sm="3" class="text-left text-md-right">
                <label class="fw-medium mb-0">Dimensions</label>
            </b-col>
            <b-col sm="9">
                <b-form-group><b-form-input id="Make" type="text" v-model="form.dimensions" placeholder="W X H X L" required></b-form-input><b-form-invalid-feedback>This field is required</b-form-invalid-feedback></b-form-group>
            </b-col>
        </b-row>
        
        <b-row class="border-bottom py-3 align-items-center">
            <b-col sm="3" class="text-left text-md-right">
                <label class="fw-medium mb-0">Equipment Speed</label>
            </b-col>
            <b-col sm="9">
                <b-form-group><b-form-input id="Make" type="text" v-model="form.equipment_speed" placeholder="..." required></b-form-input><b-form-invalid-feedback>This field is required</b-form-invalid-feedback></b-form-group>
            </b-col>
        </b-row>
        
        <b-row class="border-bottom py-3 align-items-center">
            <b-col sm="3" class="text-left text-md-right">
                <label class="fw-medium mb-0">Operation Speed</label>
            </b-col>
            <b-col sm="9">
                <b-form-group><b-form-input id="Make" type="text" v-model="form.operation_speed" placeholder="..." required></b-form-input><b-form-invalid-feedback>This field is required</b-form-invalid-feedback></b-form-group>
            </b-col>
        </b-row>
        
        <b-row class="border-bottom py-3 align-items-center">
            <b-col sm="3" class="text-left text-md-right">
                <label class="fw-medium mb-0">Operation Capacity</label>
            </b-col>
            <b-col sm="9">
                <b-form-group><b-form-input id="Make" type="text" v-model="form.operation_capacity" placeholder="..." required></b-form-input><b-form-invalid-feedback>This field is required</b-form-invalid-feedback></b-form-group>
            </b-col>
        </b-row>
        
        <b-row class="border-bottom py-3 align-items-center">
            <b-col sm="3" class="text-left text-md-right">
                <label class="fw-medium mb-0">Performance</label>
            </b-col>
            <b-col sm="9">
                <b-form-group><b-form-input id="Make" type="text" v-model="form.performance" placeholder="..." required></b-form-input><b-form-invalid-feedback>This field is required</b-form-invalid-feedback></b-form-group>
            </b-col>
        </b-row>
        
        <b-row class="py-3 align-items-center">
            <b-col sm="12">
                <b-form-group label="Resource Type">
                <b-form-radio-group
                    v-model="form.product_type"
                    :options="pTypeOptions"
                ></b-form-radio-group>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row class="border-bottom py-3 align-items-center">
            <b-col sm="3" class="text-left text-md-right">
                <label class="fw-medium mb-0">Comments</label>
            </b-col>
            <b-col sm="9">
                <b-form-group><b-form-textarea id="comments" placeholder="Comments" v-model="form.comments" rows="3" max-rows="8" no-auto-shrink></b-form-textarea><b-form-invalid-feedback>This field is required</b-form-invalid-feedback></b-form-group>
            </b-col>
        </b-row>
        <b-row class="py-3 border-bottom align-items-center">
            <b-col sm="3" class="text-left text-md-right">
                <label class="fw-medium mb-0"> Terms and Conditions</label>
            </b-col>
            <b-col sm="9">
                <b-form-file v-model="form.terms" id="file"></b-form-file>
            </b-col>
        </b-row>
        <b-row class="py-3 border-bottom align-items-center">
            <b-col sm="3" class="text-left text-md-right">
                <label class="fw-medium mb-0"> Fitness Certificate </label>
            </b-col>
            <b-col sm="9">
                <b-form-file v-model="form.fitness_certificate" id="file"></b-form-file>
            </b-col>
        </b-row>
        <b-row class="py-3 border-bottom align-items-center">
            <b-col sm="3" class="text-left text-md-right">
                <label class="fw-medium mb-0"> Operational Specification</label>
            </b-col>
            <b-col sm="9">
                <b-form-file v-model="form.operational_specs" id="file"></b-form-file>
            </b-col>
        </b-row>
    </b-card-body>
    <b-card-body class="bg-light">
        <h4 class="card-title">Products Status</h4>
        <b-row class="py-3 border-bottom align-items-center"  v-if="form.product_type &&  form.product_type === 'sale'">
            <b-col sm="3" class="text-left text-md-right">
                <label class="fw-medium mb-0"> Product Status for Selling </label>
            </b-col>
            <b-col sm="9">
                <b-form-group><b-form-select :options="sellstatus" v-model="form.product_status_id"></b-form-select><b-form-invalid-feedback>This field is required</b-form-invalid-feedback></b-form-group>
            </b-col>
        </b-row>
        <b-row class="py-3 border-bottom align-items-center"  v-if="form.product_type &&  form.product_type === 'rent'">
            <b-col sm="3" class="text-left text-md-right">
                <label class="fw-medium mb-0"> Product Status for Rental </label>
            </b-col>
            <b-col sm="9">
                <b-form-select :options="sellstatus" v-model="form.product_status_id"></b-form-select>
            </b-col>
        </b-row>
    </b-card-body>
    <b-card-body class="bg-light" v-if="form.product_type &&  form.product_type === 'rent'">
        <h4 class="card-title">Rental Details</h4>
        <b-row class="border-bottom py-3 align-items-center">
            <b-col sm="3" class="text-left text-md-right">
                <label class="fw-medium mb-0">Price</label>
            </b-col>
            <b-col sm="9">
                <b-form-input id="name" type="number" v-model="form.rental_price" placeholder="e.g 650000" required></b-form-input>
            </b-col>
        </b-row>
        <b-row class="border-bottom py-3 align-items-center">
            <b-col sm="3" class="text-left text-md-right">
                <label class="fw-medium mb-0">Mob/DeMob Cost</label>
            </b-col>
            <b-col sm="9">
                <b-form-input id="name" type="number" v-model="form.maintenance_cost" placeholder="e.g 650000" required></b-form-input>
            </b-col>
        </b-row>
        <b-row class="border-bottom py-3 align-items-center">
            <b-col sm="3" class="text-left text-md-right">
                <label class="fw-medium mb-0">Mob/DeMob Time(Hours)</label>
            </b-col>
            <b-col sm="9">
                <b-form-input id="name" type="number" v-model="form.op_ready_time" placeholder="e.g 650000" required></b-form-input>
            </b-col>
        </b-row>
        <b-row class="border-bottom py-3 align-items-center">
            <b-col sm="3" class="text-left text-md-right">
                <label class="fw-medium mb-0">Measuring Unit</label>
            </b-col>
            <b-col sm="9">
                <b-form-input id="name" type="text" v-model="form.rental_unit" placeholder="e.g Hourly" required></b-form-input>
            </b-col>
        </b-row>
        <b-row class="border-bottom py-3 align-items-center">
            <b-col sm="3" class="text-left text-md-right">
                <label class="fw-medium mb-0">Monthly Discount %</label>
            </b-col>
            <b-col sm="9">
                <b-form-input id="name" type="number" v-model="form.monthly_discount" placeholder="e.g 10" required></b-form-input>
            </b-col>
        </b-row>
        <b-row class="border-bottom py-3 align-items-center">
            <b-col sm="3" class="text-left text-md-right">
                <label class="fw-medium mb-0">Yearly Discount %</label>
            </b-col>
            <b-col sm="9">
                <b-form-input id="name" type="number" v-model="form.yearly_discount" placeholder="e.g 10" required></b-form-input>
            </b-col>
        </b-row>
        <b-row class="border-bottom py-3 align-items-center">
            <b-col sm="3" class="text-left text-md-right">
                <label class="fw-medium mb-0">Operation Years</label>
            </b-col>
            <b-col sm="9">
                <b-form-input id="example-datepicker" v-model="form.operation_years" class="mb-2"></b-form-input>
            </b-col>
        </b-row>
        <b-row class="border-bottom py-3 align-items-center">
            <b-col sm="3" class="text-left text-md-right">
                <label class="fw-medium mb-0">Add Calendar</label>
            </b-col>
            <b-col sm="9">
                <b-form-select v-model="form.calendar_template_id" :options="calendars"></b-form-select>
            </b-col>
        </b-row>
        <b-row class="border-bottom py-3 align-items-center">
            <b-col sm="3" class="text-left text-md-right">
                <label class="fw-medium mb-0">Location</label>
            </b-col>
            <b-col sm="9">
                <b-form-select v-model="form.location_id" :options="locations"></b-form-select>
            </b-col>
        </b-row>
        <b-row class="border-bottom py-3 align-items-center">
            <b-col sm="3" class="text-left text-md-right">
                <label class="fw-medium mb-0">Fitness</label>
            </b-col>
            <b-col sm="9">
                <b-form-select v-model="form.fitness_score" :options="score"></b-form-select>
            </b-col>
        </b-row>
    </b-card-body>
    <b-card-body class="bg-light" v-if="form.product_type &&  form.product_type === 'sale'">
        <h4 class="card-title">Selling Details</h4>
        <b-row class="border-bottom py-3 align-items-center">
            <b-col sm="3" class="text-left text-md-right">
                <label class="fw-medium mb-0">Price</label>
            </b-col>
            <b-col sm="9">
                <b-form-input id="name" type="number" v-model="form.purchase_price" placeholder="e.g 650000" required></b-form-input>
            </b-col>
        </b-row>
        <b-row class="border-bottom py-3 align-items-center">
            <b-col sm="3" class="text-left text-md-right">
                <label class="fw-medium mb-0">Costing Quantity</label>
            </b-col>
            <b-col sm="9">
                <b-form-input id="name" type="number" v-model="form.quantity" placeholder="Quantity" required></b-form-input>
            </b-col>
        </b-row>
        <b-row class="border-bottom py-3 align-items-center">
            <b-col sm="3" class="text-left text-md-right">
                <label class="fw-medium mb-0">Mob/DeMob Cost</label>
            </b-col>
            <b-col sm="9">
                <b-form-input id="name" type="number" v-model="form.maintenance_cost" placeholder="e.g 650000" required></b-form-input>
            </b-col>
        </b-row>
        <b-row class="border-bottom py-3 align-items-center">
            <b-col sm="3" class="text-left text-md-right">
                <label class="fw-medium mb-0">Mob/DeMob Time(Hours)</label>
            </b-col>
            <b-col sm="9">
                <b-form-input id="name" type="number" v-model="form.op_ready_time" placeholder="e.g 650000" required></b-form-input>
            </b-col>
        </b-row>
        <b-row class="border-bottom py-3 align-items-center">
            <b-col sm="3" class="text-left text-md-right">
                <label class="fw-medium mb-0">Operation Years</label>
            </b-col>
            <b-col sm="9">
                <b-form-input id="op-years" v-model="form.operation_years" class="mb-2"></b-form-input>
            </b-col>
        </b-row>
        <b-row class="border-bottom py-3 align-items-center">
            <b-col sm="3" class="text-left text-md-right">
                <label class="fw-medium mb-0">Add Calendar</label>
            </b-col>
            <b-col sm="9">
                <b-form-select v-model="form.calendar_template_id" :options="calendars"></b-form-select>
            </b-col>
        </b-row>
        <b-row class="border-bottom py-3 align-items-center">
            <b-col sm="3" class="text-left text-md-right">
                <label class="fw-medium mb-0">Location</label>
            </b-col>
            <b-col sm="9">
                <b-form-select v-model="form.location_id" :options="locations"></b-form-select>
            </b-col>
        </b-row>
        <b-row class="border-bottom py-3 align-items-center">
            <b-col sm="3" class="text-left text-md-right">
                <label class="fw-medium mb-0">Fitness</label>
            </b-col>
            <b-col sm="9">
                <b-form-select v-model="form.fitness_score" :options="score"></b-form-select>
            </b-col>
        </b-row>
    </b-card-body>
    <div class="p-35 border-top">
        <div class="btn-grp text-right">
            <b-button pill variant="primary" class="px-4" @click="updateResc()">Update</b-button>
            <b-button pill variant="danger" class="px-4">Cancel</b-button>
        </div>
    </div>
</b-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import {
  required,
} from "vuelidate/lib/validators";
export default {
    name: "Update_Resource",
    mixins: [validationMixin],
    data: () => ({
        error: false,
        success: false,
        errorMsg: null,
        successMsg: null,
        form:{
            user_id: null,
            product_type: null,
            product_status_id: null,
            location_id: null,
            category_id: null,
            calendar_template_id: null,
            name: null,
            registration: null,
            make: null,
            model: null,
            year: null,
            purchase_price: null,
            quantity: null,
            rental_price: null,
            rental_unit: null,
            monthly_discount: 0,
            yearly_discount: 0,
            operation_years: 0,
            fitness_score: null,
            fitness_certificate: "none",
            specification: "none",
            terms: "none",
            comments: null,
            op_ready_time: 0,
            maintenance_cost: 0,
            serial: null,
            engine: null,
            fuel: null,
            transmission: null,
            gross_weight: null,
            equipment_speed: null,
            operation_speed: null,
            operation_capacity: null,
            performance: null,
            operational_specs: "none",
            dimensions: null
        },
        categories: [],
        subcategories: [],
        locations: [],
        sellstatus: [],
        pTypeOptions: [
          { text: 'Sale', value: 'sale' },
          { text: 'Rent', value: 'rent' }
        ],
        score: [{
                value: null,
                text: 'Please select an option'
            },
            {
                value: 1,
                text: '1'
            },
            {
                value: 2,
                text: '2'
            },
            {
                value: 3,
                text: '3'
            },
            {
                value: 4,
                text: '4'
            },
            {
                value: 5,
                text: '5'
            },
        ],
        calendars: []
    }),
    computed:{
        ...mapState({
            authId: (state) => state.auth.authId
        })
    },
    mounted() {
        this.initComp();
    },
    validations: {
        form: {
            name: {required},
            registration: {required},
            category_id:{
                required,
            },
            product_status_id:{required},
            calendar_template_id:{required},
            location_id:{required}
        },
    },
    methods: {
        ...mapActions(["getResource","getCalendarsByUser","getLocations","getProductStatuses","getCategories","updateResource"]),
        validateState(name) {
            const { $dirty, $error } = this.$v.form[name];
            return $dirty ? !$error : null;
        },
        initComp(){
            this.getLocations().then(res => {
                res.forEach(element => {
                   this.locations.push({ value: element.id, text: element.name }); 
                });
            });
            this.getProductStatuses().then(res => {
                res.forEach(element => {
                   this.sellstatus.push({ value: element.id, text: element.name }); 
                });
            });
            this.getCategories().then(res => {
                res.forEach(element => {
                   if(element.parent_id !== null){
                       this.subcategories.push({ value: element.id, text: element.name });
                   }
                   else{
                       this.categories.push({ value: element.id, text: element.name });
                   } 
                });
            });
            this.getCalendarsByUser(this.authId).then((res) => {
                res.forEach(element => {
                   this.calendars.push({ value: element.id, text: element.name }); 
                });
            });
            this.getResource({userid: this.authId, id: this.$route.params.id}).then((res) => {
                this.form = res[0];
            })
        },
        updateResc(){
            this.form.user_id = this.authId;
            this.updateResource(this.form).then(res => {
                if('status' in res && res.status !== 200){
                    this.errorMsg = res.data;
                    this.error = true;
                    this.$bvToast.toast('Wrong or Missing Resource information', {
                        title: "Failed",
                        variant: 'danger',
                        solid: true
                    })
                }
                else{
                    this.successMsg = res;
                    this.success = true;
                    this.$bvToast.toast('Resource Updated Successfully', {
                        title: "Success",
                        variant: 'success',
                        solid: true
                    })
                }
            })
        }
    }
};
</script>
